export const updateRedirectUrlQueryParams = (
  url,
  appId,
  transactionId,
  status
) => {
  const urlObject = new URL(url);

  if (urlObject.searchParams.size > 0) {
    return `${urlObject.toString()}&transactionId=${transactionId}&status=${status}`;
  }

  if (appId === "ezs12s" || appId === "qw9rjg") {
    return `${url}?transactionId=${transactionId}&status=${status}`;
  }

  urlObject.searchParams.append("transactionId", transactionId);
  urlObject.searchParams.append("status", status);
  return urlObject.toString();
};
