import React from "react";
import PropTypes from "prop-types";
import Countdown from "react-countdown";
import "./ResponseModal.scss";
import { updateRedirectUrlQueryParams } from "../../utils/redirectUrlFormatter";
const Redirect = ({ url, transactionId, status, appId, redirectTime }) => {
  let countdownTimeInMs;
  const redirectUrl = updateRedirectUrlQueryParams(
    url,
    appId,
    transactionId,
    status
  );
  if (redirectTime === 1) countdownTimeInMs = (redirectTime + 1) * 1000;
  else countdownTimeInMs = redirectTime * 1000;
  const darkMode = localStorage.getItem("darkMode") === "yes";

  return (
    <p id="redirect__msg" className={darkMode ? "dark_font" : ""}>
      redirecting in{" "}
      <Countdown
        date={Date.now() + countdownTimeInMs}
        onTick={(time) => {
          if (time.seconds === 1) window.location = redirectUrl;
        }}
        renderer={(time) =>
          redirectTime === 1 ? (
            <span className={darkMode ? "dark_font" : ""}>1 second</span>
          ) : (
            <span className={darkMode ? "dark_font" : ""}>
              {time.seconds > 1 ? `${time.seconds} seconds` : "1 second"}
            </span>
          )
        }
      />
    </p>
  );
};

export default Redirect;

Redirect.propTypes = {
  url: PropTypes.string.isRequired,
  transactionId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
  redirectTime: PropTypes.number.isRequired,
};
